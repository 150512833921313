import React from "react"
import { SEO, Page } from "../components"
import { MainLayout } from "../layouts"
import { Header, Center, Footer } from "../partials/cities"
import { ALTO } from "../content"
import { News } from "../partials/cities/news"
import { useLocation } from '@reach/router';
import queryString from 'query-string';

export default function AltoDigital(){
  const location = useLocation();
  const newsID = String(queryString.parse(location.search).noticia);
  return(
    <MainLayout>
      <Page>
        <SEO themeColor={ALTO.COLOR} title="Alto Digital" />
        { newsID && newsID != `undefined` ? <News CITY={ALTO} NEWSID={newsID}/> : null }
        <Header CITY={ALTO} />
        <Center CITY={ALTO} />
        <Footer CITY={ALTO} />
      </Page>
    </MainLayout>
  )
}
